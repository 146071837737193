enum SubscriptionForm {
  MainPage = '1',
  DistributorPage = '2',
  BlackFriday20231013 = '3',
  Guide = '4',
  GreenFriday20241118 = '5',
  BlackFriday20241129 = '6',
}

export default SubscriptionForm
